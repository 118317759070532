import React from 'react';
import UnderConstruction from './components/UnderConstruction.jsx';
import under_construction_logo from './images/under-construction.svg'
import linkedin from './images/linkedin.svg';
import gitlab from './images/gitlab.svg';
import mail from './images/mail.svg';

const App = () => (
  <UnderConstruction
    background={{
      image: 'https://static.pexels.com/photos/259698/pexels-photo-259698.jpeg',
      textColor: '#fff',
      overlay: {
        color: '#000',
        opacity: '.5'
      }
    }}
    logo={{
      src: under_construction_logo,
      alt: 'logo',
      style: {
        width: '130px'
      }
    }}
    description={{
      text: 'Mon site est en cours de construction.',
      style: {
        maxWidth: '440px',
      }
    }}
    links={[
      {
        url: 'https://gitlab.com/pierrehusson',
        image: gitlab,
      },
      {
        url: 'https://www.linkedin.com/in/pierre-husson-/',
        image: linkedin,
      },
      {
        url: 'mailto:hussonpierre3@gmail.com',
        image: mail,
      },
    ]}
  />
);

export default App;
