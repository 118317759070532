import React from 'react';
import PropTypes from 'prop-types';
import './UnderConstruction.css';

class UnderConstruction extends React.Component {

  render() {
    const { background, description, logo, links } = this.props;

    if (background.overlay && background.overlay.color && !background.overlay.opacity) {
      console.error("background.overlay.opacity is required when using background.overlay");
    }

    return (
      <div
        className="flex flex-col justify-center items-center text-white bg-no-repeat bg-cover bg-center h-full w-full"
        style={{
          backgroundImage: `url(${background.image})`,
          backgroundColor: background.color,
          color: background.textColor,
          ...background.style
        }}
      >
        {background.overlay && background.overlay.color &&
          <div
            style={{
              backgroundColor: background.overlay.color,
              opacity: background.overlay.opacity,
            }}
            className="absolute top-0 left-0 w-full h-full bg-no-repeat bg-cover bg-center"
          />}

        <div className="flex-row content-center -mt-32 md:-mt-12 w-full max-w-4xl relative">
          {logo.src && 
            <div className="mx-auto flex justify-center">
              <img
                className="w-32 filter invert opacity-80"
                alt={logo.alt}
                src={logo.src}
                style={{ ...logo.style }}
              />
            </div>}         

          {description.text &&
            <div
              className="mx-auto mt-5 md:text-2xl text-base flex justify-center "
              style={{ ...description.style }}
            >
              {description.text}
            </div>}
        </div>  

        {links && links.length > 0 && 
          <div className="w-full fixed text-center bottom-8">
            {links.map((social, i) => (
              <a
                className="text-white opacity-100 inline-block no-underline transition-all mr-5 hover:opacity-80"
                key={i}
                target='_blank'
                rel="noopener noreferrer"
                href={social.url}
              >
                {social.image &&
                  <img
                    className="w-14 filter invert"
                    src={social.image}
                    alt={social.text}
                    style={{ ...social.imageStyle }}
                  />}
              </a>
            ))}
          </div>}
      </div>
    );
  }
}

UnderConstruction.defaultProps = {
  background: {
    image: '',
    color: '',
    textColor: '',
    overlay: {},
    style: {},
  },
  logo: {
    src: '',
    alt: '',
    style: {},
  },
  description: {
    text: '',
    style: {},
  },
  links: [{
    url: '',
    image: '',
    imageStyle: {},
  }],
};

UnderConstruction.propTypes = {
  background: PropTypes.shape({
    image: PropTypes.string,
    color: PropTypes.string,
    textColor: PropTypes.string,
    style: PropTypes.object,
    overlay: PropTypes.shape({
      color: PropTypes.string,
      opacity: PropTypes.string,
    })
  }),
  logo: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    style: PropTypes.object,
  }),
  description: PropTypes.shape({
    text: PropTypes.string,
    style: PropTypes.object,
  }),
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    image: PropTypes.string,
    imageStyle: PropTypes.object,
  })),
};

export default UnderConstruction;
